<template>
    SEE REVIEW
   </template>
   <script>
     export default {
       data: () => ({
         show: false,
       }),
     }
   </script>
   
   <!-- Add "scoped" attribute to limit CSS to this component only -->
   <style scoped>
   </style>